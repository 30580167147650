<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영수증코드</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.receiptCode"
                  cssClass="lookup-condition-multiselect"
                  :allowFiltering="false"
                  :dataSource="searchOptions.receiptCode"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="300"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">영수증코드 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="receiptCodeRegistration.shortcuts.addReceiptCode"
                      :shortcut="{key: 'F3'}"
                      @click.native="onAddButtonClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="tsConfReceiptMstGrid"
                :dataSource="tsConfReceiptMstList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="tsConfReceiptMstGridColumn"
                :provides="grid"
                :isInPopup="true"
                :allowPaging="true"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :isAutoSelectCell="!isModifyMode"
                :pageSettings="tsConfReceiptMstPageSettings"
                :isCustomizeKeyboardAction="true"
                :validationRules="validationRules"
                @headerCellInfo="tsConfReceiptMstGridHeaderCellInfo"
                @queryCellInfo="tsConfReceiptMstGridQueryCellInfo"
                @actionComplete="tsConfReceiptMstGridActionComplete"
                @recordClick="onTsConfReceiptMstGridRecordClick"
                @cellEdit="onTsConfReceiptMstGridCellEdit"
                @cellSaved="onTsConfReceiptMstGridCellSaved"
                @cellSelected="onTsConfReceiptMstGridCellSelected"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <receipt-code-detail-popup
      ref="receiptCodeDetailPopup"
      v-if="isVisibleReceiptCodeDetailPopup"
      @popupClosed="closeReceiptCodeDetailPopup"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .article-section.section-01 .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Edit,
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
  Page,
} from "@syncfusion/ej2-vue-grids";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import receiptCodeDetailPopup from "../popup/ReceiptCodeDetailPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ReceiptCodeRegistration",
  components: {
    ejsGridWrapper,
    receiptCodeDetailPopup,
    editMultipleColumnsPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    this.tsConfReceiptMstGridColumn.forEach((column) => {
      this.editableFields[column.field] = !!column.allowEditing;
    });
  },
  data() {
    return {
      count: 0,
      commonCodeFields: { text: "comName", value: "comCode" },
      isVisibleReceiptCodeDetailPopup: false,
      isEditMultipleColumnsPopupOpen: false,
      grid: [
        Edit,
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        Page,
      ],
      searchConditions: {
        receiptCode: [],
      },
      searchOptions: {
        receiptCode: commonCodesGetCommonCode("RECEIPT_CODE", false),
      },
      editableFields: {},
      tsConfReceiptMstLength: 0,
      tsConfReceiptMstList: [],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      tsConfReceiptMstPageSettings: { pageSize: 50 },
      tsConfReceiptMstGridColumn: [
        {
          field: "receiptId",
          visible: false,
          allowEditing: false,
          isPrimaryKey: true,
        },
        {
          field: "receiptCode",
          headerText: "영수증코드",
          width: 150,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "RECEIPT_CODE",
          type: "string",
        },
        {
          field: "logoFlag",
          headerText: "로고표시",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "sortCode",
          headerText: "정렬코드",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "SORT_CODE",
          allowEditing: true,
          type: "string",
        },
        {
          field: "lineFlag",
          headerText: "라인표시",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "lineWidth",
          headerText: "라인길이",
          width: 90,
          textAlign: "right",
          allowEditing: true,
          type: "number",
          format: "N",
          multiEdit: {
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          field: "lineLocation",
          headerText: "라인위치",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "SORT_CODE",
          allowEditing: true,
          type: "string",
        },
        {
          field: "linePaddingFlag",
          headerText: "한줄공백",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "loopFlag",
          headerText: "반복여부",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "loopName",
          headerText: "반복변수",
          width: 120,
          allowEditing: true,
          type: "string",
        },
        {
          field: "unPrtFlag",
          headerText: "미출력",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "unPrtVariable",
          headerText: "미출력기준변수",
          width: 120,
          allowEditing: true,
          type: "string",
        },
        {
          field: "cutFlag",
          headerText: "영수증절단",
          width: 100,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "sortNo",
          headerText: "정렬번호",
          width: 90,
          textAlign: "right",
          allowEditing: true,
          type: "number",
          format: "N",
          multiEdit: {
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          field: "useFlag",
          headerText: "사용",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          allowEditing: true,
        },
        {
          field: "insertName",
          headerText: "등록자",
          width: 90,
          allowEditing: false,
          type: "string",
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          width: 150,
          allowEditing: false,
          type: "string",
        },
        {
          field: "updateName",
          headerText: "수정자",
          width: 90,
          allowEditing: false,
          type: "string",
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          width: 150,
          allowEditing: false,
          type: "string",
        },
      ],
      validationRules: {
        receiptCode: {
          required: true,
        },
      },
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isVisibleReceiptCodeDetailPopup ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
  },
  methods: {
    numberWithCommas,
    async onViewButtonClicked() {
      await this.fetchTsConfReceiptMsts(this.searchConditions.receiptCode);
    },
    async fetchTsConfReceiptMsts(receiptCode) {
      this.tsConfReceiptMstList = await GolfErpAPI.fetchTsConfReceiptMsts(
        receiptCode
      );
      this.tsConfReceiptMstLength = this.tsConfReceiptMstList.length;
    },
    tsConfReceiptMstGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (
        field === "receiptCode" ||
        field === "sortCode"
      ) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (
        args.cell.column &&
        (field === "_rid" || field === undefined) &&
        !this.isModifyMode
      ) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.tsConfReceiptMstGridColumn
          ?.filter(item => item.allowEditing && item.field !== "receiptCode")
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.tsConfReceiptMstGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    tsConfReceiptMstGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (
        args.column &&
        (field === "_rid" || field === undefined) &&
        !this.isModifyMode
      ) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (
        this.editableFields[field] ||
        (
          args.column &&
          field === "receiptCode" &&
          (data.receiptId === null || data.receiptId === undefined)
        )
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !data.useFlag) {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.tsConfReceiptMstGridColumn
          ?.filter(item => item.allowEditing && item.field !== "receiptCode")
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    tsConfReceiptMstGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.tsConfReceiptMstGrid?.getGridBatchCount() || 0
      );
    },
    onTsConfReceiptMstGridRecordClick(args) {
      if (
        args.column &&
        args.column.headerText === "NO" &&
        args.rowData.receiptId != null &&
        !this.isModifyMode
      ) {
        const popupData = {
          receiptId: args.rowData.receiptId,
          receiptCode: args.rowData.receiptCode,
        };
        this.openReceiptCodeDetailPopup(popupData);
      }
    },
    openReceiptCodeDetailPopup(popupData) {
      this.isVisibleReceiptCodeDetailPopup = true;
      this.$nextTick(() => {
        this.$refs.receiptCodeDetailPopup.showReceiptCodeDetailPopup(popupData);
      });
    },
    closeReceiptCodeDetailPopup() {
      this.isVisibleReceiptCodeDetailPopup = false;
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.tsConfReceiptMstGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onAddButtonClicked() {
      this.$refs.tsConfReceiptMstGrid.addRecord({
        receiptId: null,
        receiptCode: null,
        logoFlag: false,
        sortCode: "CENTER",
        lineFlag: false,
        lineWidth: 0,
        lineLocation: null,
        linePaddingFlag: false,
        loopFlag: false,
        loopName: null,
        unPrtFlag: false,
        unPrtVariable: null,
        cutFlag: false,
        sortNo: null,
        useFlag: true,
      });
    },
    validateRecords(records) {
      for (let i = 0; i < records.length; i++) {
        const { receiptCode } = records[i];
        if (!receiptCode) {
          return false;
        }
      }

      return true;
    },
    async onSaveButtonClicked() {
      const {
        addedRecords,
        changedRecords,
      } = this.$refs.tsConfReceiptMstGrid.getBatchChanges();

      if (
        !this.validateRecords(addedRecords) ||
        !this.validateRecords(changedRecords)
      ) {
        this.errorToast("필수값이 입력되지 않았습니다");
        return;
      }

      let saveData = [];

      if (addedRecords.length > 0) {
        addedRecords.forEach((data) => {
          saveData.push(data);
        });
      }
      if (changedRecords.length > 0) {
        changedRecords.forEach((data) => {
          saveData.push(data);
        });
      }

      await GolfErpAPI.patchTsConfReceiptMsts(saveData);

      this.infoToast(this.$t("main.popupMessage.saved"));

      await this.onViewButtonClicked();
    },
    onTsConfReceiptMstGridCellEdit(args) {
      if (
        (args.columnName === "lineWidth" ||
          args.columnName === "lineLocation") &&
        !args.rowData.lineFlag
      ) {
        // 라인표시가 체크되어야 라인길이 및 라인위치 편집 가능.
        args.cancel = true;
      } else if (args.columnName === "loopName" && !args.rowData.loopFlag) {
        // 반복여부가 체크되어야 반복변수 편집 가능.
        args.cancel = true;
      } else if (
        args.columnName === "unPrtVariable" &&
        !args.rowData.unPrtFlag
      ) {
        // 미출력이 체크되어야 미출력기준변수 편집 가능.
        args.cancel = true;
      } else if (
        args.columnName === "receiptCode" &&
        args.rowData.receiptId !== null &&
        args.rowData.receiptId !== undefined
      ) {
        args.cancel = true;
      }
    },
    onTsConfReceiptMstGridCellSaved(args) {
      const col = args.columnName;
      const data = args.value;
      const previousData = args.previousValue;
      const receiptId = args.rowData.receiptId;
      const rowIndex = this.$refs.tsConfReceiptMstGrid.getRowIndexByPrimaryKey(
        receiptId
      );

      if (col === "lineFlag" && !data && data !== previousData) {
        // 라인표시 체크를 해제하면 라인길이 및 라인위치 초기화.
        if (
          args.rowData.lineWidth !== null &&
          args.rowData.lineWidth !== undefined &&
          args.rowData.lineWidth !== 0
        ) {
          this.$refs.tsConfReceiptMstGrid.updateCell(rowIndex, "lineWidth", 0);
        }
        if (
          args.rowData.lineLocation !== null &&
          args.rowData.lineLocation !== undefined
        ) {
          this.$refs.tsConfReceiptMstGrid.updateCell(
            rowIndex,
            "lineLocation",
            null
          );
        }
      } else if (col === "loopFlag" && !data && data !== previousData) {
        // 반복여부 체크를 해제하면 반복변수 초기화.
        if (
          args.rowData.loopName !== null &&
          args.rowData.loopName !== undefined
        ) {
          this.$refs.tsConfReceiptMstGrid.updateCell(
            rowIndex,
            "loopName",
            null
          );
        }
      } else if (col === "unPrtFlag" && !data && data !== previousData) {
        // 미출력 체크를 해제하면 미출력기준변수 초기화.
        if (
          args.rowData.unPrtVariable !== null &&
          args.rowData.unPrtVariable !== undefined
        ) {
          this.$refs.tsConfReceiptMstGrid.updateCell(
            rowIndex,
            "unPrtVariable",
            null
          );
        }
      }
    },
    onClickExcel() {
      this.$refs.tsConfReceiptMstGrid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        };
      this.$refs.tsConfReceiptMstGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async onTsConfReceiptMstGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.tsConfReceiptMstGridColumn
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
  },
};
</script>
