<template>
  <div>
    <ejs-dialog
      ref="receiptCodeDetailPopup"
      header="영수증코드 상세"
      width="1400"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :showCloseIcon="true"
      :allowDragging="true"
      :isModal="true"
      :close="onReceiptCodeDetailPopupClosed"
    >
      <div class="window commonCodeDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        영수증코드 목록({{ receiptCode }} /
                        {{
                          commonCodesGetComName("RECEIPT_CODE", receiptCode)
                        }})
                      </div>
                      <div class="header-caption">
                        [{{ numberWithCommas(tsConfReceiptDtlLength) }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input
                                      type="checkbox"
                                      v-model="searchConditions.useFlag"
                                    />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <li>
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              :is-shortcut-button="true"
                              @click.native="onViewButtonClicked"
                          >
                            조회
                          </erp-button>
                        </li>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="receiptCodeDetailPopup.shortcuts.addReceiptCodeDetail"
                              :shortcut="{key: 'F3'}"
                              @click.native="onAddButtonClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="tsConfReceiptDtlGrid"
                        :allowResizing="true"
                        :dataSource="tsConfReceiptDtlList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="tsConfReceiptDtlGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :isInPopup="true"
                        @headerCellInfo="tsConfReceiptDtlGridHeaderCellInfo"
                        @queryCellInfo="tsConfReceiptDtlGridQueryCellInfo"
                        @actionComplete="tsConfReceiptDtlGridActionComplete"
                        @cellEdit="onTsConfReceiptDtlGridCellEdit"
                        @cellSaved="onTsConfReceiptDtlGridCellSaved"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseReceiptCodeDetailPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .appContent .article-section.section-01 .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Edit,
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ReceiptCodeDetailPopup",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      grid: [Edit, Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      searchConditions: {
        receiptId: null,
        useFlag: true,
      },
      receiptCode: null,
      tsConfReceiptDtlLength: 0,
      tsConfReceiptDtlList: [],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      tsConfReceiptDtlGridColumn: [
        {
          field: "dtlId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "receiptId",
          visible: false,
        },
        {
          field: "variableFlag",
          headerText: "변수사용",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
        },
        {
          field: "variableName",
          headerText: "변수명",
          width: 150,
        },
        {
          field: "variableType",
          headerText: "변수타입",
          width: 100,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "VARIABLE_TYPE",
          isDropdowneditInit: true,
        },
        {
          field: "variableFormat",
          headerText: "변수포맷",
          width: 120,
        },
        {
          field: "contents",
          headerText: "내용",
          width: 180,
        },
        {
          field: "contentsWidth",
          headerText: "내용길이",
          width: 90,
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "paddingLocation",
          headerText: "여백위치",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "SORT_CODE",
        },
        {
          field: "lineFeedFlag",
          headerText: "줄바꿈여부",
          width: 100,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
        },
        {
          field: "fontSize",
          headerText: "폰트크기",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "FONT_SIZE",
        },
        {
          field: "sortNo",
          headerText: "정렬번호",
          width: 90,
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "useFlag",
          headerText: "사용",
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
        },
        {
          field: "insertName",
          headerText: "등록자",
          width: 90,
          allowEditing: false,
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          width: 150,
          allowEditing: false,
        },
        {
          field: "updateName",
          headerText: "수정자",
          width: 90,
          allowEditing: false,
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          width: 150,
          allowEditing: false,
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    commonCodesGetComName,
    async showReceiptCodeDetailPopup(popupData) {
      this.searchConditions.receiptId = popupData.receiptId;
      this.receiptCode = popupData.receiptCode;

      await this.onViewButtonClicked();
    },
    onReceiptCodeDetailPopupClosed() {
      this.$emit("popupClosed");
    },
    onCloseReceiptCodeDetailPopup() {
      this.$refs.receiptCodeDetailPopup.hide();
    },
    async onViewButtonClicked() {
      this.$refs.tsConfReceiptDtlGrid.clearFiltering();

      await this.fetchTsConfReceiptDtls(
        this.searchConditions.receiptId,
        this.searchConditions.useFlag
      );
    },
    async fetchTsConfReceiptDtls(receiptId, useFlag = false) {
      this.tsConfReceiptDtlList = await GolfErpAPI.fetchTsConfReceiptDtls(
        receiptId,
        useFlag
      );

      this.tsConfReceiptDtlLength = this.tsConfReceiptDtlList.length;
    },
    tsConfReceiptDtlGridHeaderCellInfo(args) {
      if (args.cell.column.field === "receiptCode") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    tsConfReceiptDtlGridQueryCellInfo(args) {
      if (
        args.column &&
        args.column.field !== "insertId" &&
        args.column.field !== "insertDt" &&
        args.column.field !== "updateId" &&
        args.column.field !== "updateDt"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    tsConfReceiptDtlGridActionComplete(args) {
      if (args.requestType === "filtering" && args.action === "clearFilter") {
        this.tsConfReceiptDtlLength = this.tsConfReceiptDtlList.length;
      } else if (args.requestType === "filtering") {
        this.tsConfReceiptDtlLength = this.$refs.tsConfReceiptDtlGrid.getFilteredRecords().length;
      }
    },
    onAddButtonClicked() {
      this.$refs.tsConfReceiptDtlGrid.addRecord({
        receiptId: this.searchConditions.receiptId,
        variableFlag: false,
        variableName: null,
        variableType: null,
        variableFormat: null,
        contents: null,
        contentsWidth: 0,
        paddingLocation: null,
        lineFeedFlag: false,
        fontSize: null,
        sortNo: null,
        useFlag: true,
      });
    },
    async onSaveButtonClicked() {
      const {
        addedRecords,
        changedRecords,
      } = this.$refs.tsConfReceiptDtlGrid.getBatchChanges();

      let saveData = [];

      if (addedRecords.length > 0) {
        addedRecords.forEach((data) => {
          saveData.push(data);
        });
      }
      if (changedRecords.length > 0) {
        changedRecords.forEach((data) => {
          saveData.push(data);
        });
      }

      await GolfErpAPI.patchTsConfReceiptDtls(saveData);

      await this.onViewButtonClicked();
    },
    onTsConfReceiptDtlGridCellEdit(args) {
      if (
        (args.columnName === "variableName" ||
          args.columnName === "variableType" ||
          args.columnName === "variableFormat") &&
        !args.rowData.variableFlag
      ) {
        // 변수사용이 체크되어야 변수명 및 변수타입, 변수포맷 편집 가능.
        args.cancel = true;
      }
    },
    onTsConfReceiptDtlGridCellSaved(args) {
      const col = args.columnName;
      const data = args.value;
      const previousData = args.previousValue;
      const dtlId = args.rowData.dtlId;
      const rowIndex = this.$refs.tsConfReceiptDtlGrid.getRowIndexByPrimaryKey(
        dtlId
      );

      if (col === "variableFlag" && !data && data !== previousData) {
        // 변수사용 체크를 해제하면 변수명 및 변수타입, 변수포맷 초기화.
        if (
          args.rowData.variableName !== null &&
          args.rowData.variableName !== undefined
        ) {
          this.$refs.tsConfReceiptDtlGrid.updateCell(
            rowIndex,
            "variableName",
            null
          );
        }
        if (
          args.rowData.variableType !== null &&
          args.rowData.variableType !== undefined
        ) {
          this.$refs.tsConfReceiptDtlGrid.updateCell(
            rowIndex,
            "variableType",
            null
          );
        }
        if (
          args.rowData.variableFormat !== null &&
          args.rowData.variableFormat !== undefined
        ) {
          this.$refs.tsConfReceiptDtlGrid.updateCell(
            rowIndex,
            "variableFormat",
            null
          );
        }
      }
    },
  },
};
</script>
